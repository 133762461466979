import { AppSettings } from "booking_app/values/app-settings";

interface GaValues {
  name: string;
  value: any;
}

export class GoogleAnalyticsService {
  static $inject = ["$rootScope", "$window", "AppSettings"];

  // Bindings
  gaParameters: any;
  gaValues: GaValues[];

  constructor(
    private $rootScope: any,
    private $window: any,
    private appSettings: AppSettings,
  ) {
    this.$onInit();
  }

  public $onInit(): void {
    const date: any = new Date();
    const num: number = 1;
    const gaObject = "GoogleAnalyticsObject";

    // Attach google analytics to window
    ((i, s, o, g, r, a, m) => {
      i[gaObject] = r;
      i[r] = i[r] || function _() {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = num * date;
      a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(this.$window, document, "script", "//www.google-analytics.com/analytics.js", "ga");
  }

  public setGa(gaParameters: any): void {
    if (!this.$window.ga) {
      return;
    }

    this.gaParameters = gaParameters;
    this.gaParameters.forEach((item) => {
      if (item.value) {
        this.$window.ga(item.name, item.value);
      } else {
        this.$window.ga(item.name);
      }
    });
  }

  public commonGa(): void {
    this.setCommonGaValues();
    this.setGa(this.gaValues);
  }

  private setCommonGaValues(): void {
    this.gaValues = [
      {
        name: "create",
        value: this.appSettings.gauid,
      },
    ];

    if (this.appSettings.displayFeatures) {
      this.gaValues.push(
        {
          name: "require",
          value: "displayfeatures",
        },
      );
    }

    if (this.currentUserId()) {
      this.gaValues.push(
        {
          name: "set",
          value: ["&uid", this.currentUserId()],
        },
      );
    }
  }

  private currentUserId(): number {
    if (this.$rootScope.userDetails.user) {
      return this.$rootScope.userDetails.user.id;
    }
  }
}

angular.module("BookingApp").service("GoogleAnalyticsService", GoogleAnalyticsService);
