require('../services/bookings')
require('../services/hotel-util.service')
require('../factories/booking-policy-runner')
require('../services/memberships')
require('../services/destinations.service')
require('../services/booking-errors-mapper.service')
require('../services/booking-transactions')
require('../services/search-url')
require('../services/card-type.service')
require('../services/ccvalidation')
require('../services/checkout')
require('../services/checkout/hotels')
require('../services/coupon.service')
require('../services/pay-with-points-cash.service')
require('../constants/constants')
require('../values/app-traffic')
require('../services/country.service')
require('../services/api-data.service')
require('../services/input-validation.service')
require('../services/utils')
require('../services/tracker')
require('../services/payment')
require('../services/stripe-payment.service')
require('../services/stripe-payment-intent.service')
require('../services/checkout_com_kit')
require('../services/whitelabel-partner')
require('../services/currencies.service')
require('../services/populate-checkout.service')
require('../services/pay-with-points-cash-complimentary-nights')
require('../services/points-cash-share.service')
require('../services/checkout-options')

require('../filters/filter')
require('../filters/format-number')

require('../directives/scroll-if')
require('../directives/validate-credit-card')

require('../services/payment-method.service')
require('../services/payments/pay-anyone.service')
require('../services/checkout-validation/checkout-validation.service')
require('../services/payments/payment-timer.service')
require('../services/points-adjustment.service')
require('../services/adyen-form.service')
require('../services/points-cash-payment-handling.service')

require('../utils/user-agent-checker')

angular.module('BookingApp')

.controller 'HotelsCheckoutCtrl', [

  '$scope', '$rootScope', '$window', '$timeout', '$location', '$modal', '$filter', '$translate', 'KaligoConfig',
  'HotelsCheckoutPrefetch', 'BookingsService', 'HotelUtilService', 'BookingPolicyRunner',
  'MembershipsService', 'DestinationsService', 'BookingErrorsMapper', 'BookingTransactionsService', 'SearchUrlService',
  'CCValidationService', 'CheckoutStatusService',
  'HotelImageService', 'KaligoConstants',
  'WhitelabelPartner', 'PayWithPointsCashService', 'SortService', 'CountryService',
  'AppTraffic', 'AppSettings',
  'StripePaymentService', 'StripePaymentIntentService',
  'ApiDataService', 'CheckoutComKitService', 'AppConfig', 'UtilsService',
  'AppState', 'TrackerService', 'PaymentService', 'CurrenciesService',
  'CouponService', 'PaymentMethodService', 'PopulateCheckoutService',
  'UserAgentChecker', 'CheckoutValidationService',
  'GlobalStateService', "PaymentTimerService", 'HotelPricePopulateService',
  'PointsAdjustmentService', 'CheckoutFormService',
  'PayWithPointsCashCompNights', 'PointsCashShareService', 'CheckoutOptions',
  'SimpleModalService', 'AdyenFormService', 'PointsCashPaymentHandlingService'

  ($scope, $rootScope, $window, $timeout, $location, $modal, $filter, $translate, KaligoConfig,
  HotelsCheckoutPrefetch, BookingsService, HotelUtilService, BookingPolicyRunner,
  MembershipsService, DestinationsService, BookingErrorsMapper, BookingTransactionsService, SearchUrlService,
  CCValidationService, CheckoutStatusService,
  HotelImageService, KaligoConstants,
  WhitelabelPartner, PayWithPointsCashService, SortService, CountryService,
  AppTraffic, AppSettings, StripePaymentService, StripePaymentIntentService,
  ApiDataService, CheckoutComKitService,
  AppConfig, UtilsService, AppState, TrackerService, PaymentService,
  CurrenciesService, CouponService, PaymentMethodService, PopulateCheckoutService,
  UserAgentChecker, CheckoutValidationService, GlobalStateService, PaymentTimerService,
  HotelPricePopulateService, PointsAdjustmentService, CheckoutFormService,
  PayWithPointsCashCompNights, PointsCashShareService, CheckoutOptions,
  SimpleModalService, AdyenFormService, PointsCashPaymentHandlingService) ->

    if $rootScope.globalState.requireLoginOnCheckout && !$rootScope.userDetails.loggedIn
      $location.url(SearchUrlService.createBackToHotelDetailsUrl())
      return

    GlobalStateService.currentPage = "checkout"
    $scope.hotelDetail = HotelsCheckoutPrefetch
    HotelImageService.initHotelImageData([$scope.hotelDetail])
    $scope.creditCardExpirationMonthOptions = CheckoutOptions.creditCardExpirationMonths()
    $scope.creditCardExpirationYearOptions = CheckoutOptions.creditCardExpirationYears()
    $scope.guestTitleOptions = CheckoutOptions.guestTitleOptions();
    $scope.paymentLoadingMsg = "payment_in_progress"

    $scope.voucherCode = ""
    $rootScope.vouchers = $scope.vouchers = []
    $window.scrollTo(0, 0)

    # needs to be in object format, VoucherCtrl require this object too
    $scope.partnerScore = {
      base: 0
      bonus: 0
    }

    $scope.constants = KaligoConstants

    CheckoutFormService.init($scope)
    $scope.tenant = AppSettings.tenant.toLowerCase()
    $scope.earnOnBurn = AppSettings.payWithPoints.earnOnBurn
    $scope.insufficientPointsCheck = AppSettings.insufficientPointsCheck
    $scope.namePlaceholder = AppSettings.checkoutPagePlaceholderText
    $scope.hideCheckoutFormPostalCode = AppSettings.hideCheckoutFormPostalCode
    $scope.globalStateService = GlobalStateService
    $scope.pointsCashShareService = PointsCashShareService
    $scope.paymentTimerService = PaymentTimerService
    $scope.defaultCurrencyDisplay = AppSettings.defaultCurrencyDisplay
    $scope.showVatInfo = AppSettings.showVatInfo
    $scope.showLoyaltyProgramTnC = AppSettings.showLoyaltyProgramTnC

    $scope.childrenCount = UtilsService.getchildrenCount($rootScope.guests)
    $scope.childrenAges = "(#{UtilsService.getChildrenAgesFromGuests($rootScope.guests).join(", ")})"

    $scope.showCancelPolicy = () ->
      if $rootScope.globalState.productType == "redeem"
        AppSettings.showCancelPolicyOnRedeem
      else if $rootScope.globalState.productType == "earn"
        AppSettings.showCancelPolicyOnEarn
      else if $rootScope.globalState.productType == "voucher"
        false

    $scope.paymentMethod = PaymentMethodService

    $scope.showRapidVersion = () ->
      $scope.checkoutState.knowBeforeYouGo ||
      $scope.checkoutState.feesOptional ||
      $scope.checkoutState.feesMandatory

    invalidVoucher = () ->
      return (
        $rootScope.landingPage && $rootScope.landingPage.hasProductType("voucher") &&
        $scope.vouchers.length < $scope.totalNights
      )

    insufficientPoints = () ->
      return (
        AppSettings.insufficientPointsCheck &&
        $rootScope.landingPage && $rootScope.landingPage.hasProductType("redeem") &&
        $scope.hotelPrice &&
        $rootScope.userDetails.user.redemption_points_balance < $scope.hotelPrice.points_payment
      )

    $scope.notAllowedToBook = () ->
      return invalidVoucher() || insufficientPoints()

    $scope.checkoutState = {
      showForSomeoneElse: false
      displayPoweredByKaligo: WhitelabelPartner.isWhiteLabel
      errorKey: ""
      showPaymentForm: () ->
        $rootScope.landingPage.showCheckoutPaymentForm() ||
        ($scope.hotelPrice && $scope.hotelPrice.cash_payment > 0)
      displayUserDetail: () ->
        return true if $rootScope.globalState.alwaysAskForEmail
        if $rootScope.userDetails.user && $rootScope.globalState.useMembershipNoAsEmail
          $scope.bookingDetails.guest.email = $rootScope.userDetails.user.redemption_member_no
          return !$rootScope.userDetails.user.redemption_member_no
        else
          (
            !$rootScope.userDetails.loggedIn ||
            !$rootScope.userDetails.user.email ||
            !$rootScope.userDetails.user.first_name
          )
      displayBookingForSomeone: () ->
        !$rootScope.landingPage.hasProductType("voucher") && $rootScope.pointsPartner.category == "airline"
      salesTaxIncluded: () ->
        $translate.instant("checkout.label.include_tax", {
          "tax": $translate.instant('checkout.label.sales_tax')
          "currency": $rootScope.selectedCurrency.code
          "amount": $scope.hotelPrice.salesTax()
        })
      hotelOccupancyTaxIncluded: () ->
        $translate.instant("checkout.label.include_tax", {
          "tax": $translate.instant('checkout.label.hotel_occupancy')
          "currency": $rootScope.selectedCurrency.code
          "amount": $scope.hotelPrice.hotelOccupancyTax()
        })
      propertyFeeIncluded: () ->
        $translate.instant("checkout.label.include_tax", {
          "tax": $translate.instant('checkout.label.property_fee')
          "currency": $rootScope.selectedCurrency.code
          "amount": $scope.hotelPrice.propertyFee()
        })
      displayFamilyMiles: () ->
        $scope.hotelPrice &&
        $rootScope.userDetails.user &&
        $rootScope.userDetails.user.family_miles > $scope.hotelPrice.points_payment &&
        $rootScope.userDetails.user.family_miles > $rootScope.userDetails.user.redemption_points_balance
      labelTaxRecoveryCharges: () ->
        country = CountryService.getCountry($scope.hotelDetail.original_metadata.country)
        if country && country.continent == 'EU'
          $translate.instant("checkout.label.tax_recovery_charges_only")
        else
          $translate.instant("checkout.label.tax_recovery_charges")

      checkinInstructions: ""
      specialCheckinInstructions: ""
      knowBeforeYouGo: ""
      feesOptional: ""
      feesMandatory: ""
      selectedCreditCard: false
      userPaymentMethodIsFetching: false
      saveCreditCard: false
    }

    $scope.bookingForSomeoneInfo = false
    $scope.bookingForSomeoneElsePopup = (showPopup) ->
      $scope.bookingForSomeoneInfo = showPopup

    $scope.numOfNights = $filter("getNumberOfDays")($rootScope.checkInDate, $rootScope.checkOutDate)
    $scope.totalNights = $scope.numOfNights * $rootScope.roomCount
    $scope.nightsArr = [0...$scope.totalNights]

    fetchBookingStatus = ->
      CheckoutStatusService.checkTransactionStatus($rootScope.bookingTransactionId)

    $scope.isPaymentLoading = ->
      CheckoutStatusService.isLoading || $scope.paymentIsLoading

    $scope.paymentLoadingMessage = ->
      CheckoutStatusService.loadingMessage || $scope.paymentLoadingMsg

    $scope.errorCode = ->
      CheckoutStatusService.errorCode

    $scope.$watch "errorCode()", ->
      $scope.checkoutState.errorKey = $scope.errorCode() if $scope.errorCode()

    if $rootScope.bookingTransactionId != undefined
      transactionsService = new BookingTransactionsService($rootScope.bookingTransactionId)
      transactionsService.fetchInfo().then (response) ->
        transactionsService.loadCheckoutData($scope, response)
      fetchBookingStatus()
      PaymentTimerService.paymentInProgressTimer()

    displayHotelDatesFormat = ->
      $scope.displayCheckInDate = moment($rootScope.checkInDate).format(AppSettings.dateFormat);
      $scope.displayCheckOutDate = moment($rootScope.checkOutDate).format(AppSettings.dateFormat);

    $scope.creditcard = {type:undefined}

    populateMembershipDetails = (memberships) ->
      $scope.memberships = memberships
      if $rootScope.bookingTransactionId != undefined
        transactionsService.fetchInfo().then (transaction) ->
          transactionMembership = {
            member_no: transaction.memberNo,
            member_first_name: transaction.memberFirstName,
            member_last_name: transaction.memberLastName
          }
          $scope.bookingDetails.selectedMembership = MembershipsService.selectMembership($scope.memberships, $scope.newMembership, transactionMembership)
      else
        $scope.bookingDetails.selectedMembership = $scope.memberships[0] || $scope.newMembership

    getMembershipDetails = ->
      MembershipsService.getMembershipDetails($rootScope.pointsPartner.id).then (memberships) ->
        populateMembershipDetails(memberships)

    $scope.autoPopulateAddress = true

    isAddressFilled = ->
      $rootScope.userDetails.user.address1 or
      $rootScope.userDetails.user.city or
      $rootScope.userDetails.user.zip_code or
      $rootScope.userDetails.user.country

    populateAddressFields = ->
      $scope.bookingDetails.guest.email      = $rootScope.userDetails.user.email
      $scope.bookingDetails.guest.street     = $rootScope.userDetails.user.address1
      $scope.bookingDetails.guest.city       = $rootScope.userDetails.user.city
      $scope.bookingDetails.guest.postalCode = $rootScope.userDetails.user.zip_code
      if country
        $scope.bookingDetails.guest.country  = {
          id: country.code,
          code: country.code,
          text: country.name
        }

    clearAddressFields = ->
      $scope.bookingDetails.guest.street        = null
      $scope.bookingDetails.guest.streetNumber  = null
      $scope.bookingDetails.guest.city          = null
      $scope.bookingDetails.guest.postalCode    = null
      $scope.bookingDetails.guest.country       = null

    openHotelRoomUnavailableModal = ->
      SimpleModalService.open("hotel-room-unavailable-modal").then(() ->
        $scope.redirectToHotelDetailsPage()
      )

    # Pre-populate the booking form model & call the membership API if the user is signed in
    if $rootScope.userDetails.loggedIn
      getMembershipDetails()
      $scope.bookingDetails.guest =
        PopulateCheckoutService.populateFields($scope.bookingDetails.guest)
      $scope.bookingDetails.user =
        PopulateCheckoutService.populateFields($scope.bookingDetails.user)
      unless isAddressFilled()
        $scope.autoPopulateAddress = false

      $scope.bookingDetails.guest.firstName = $rootScope.userDetails.user.first_name
      $scope.bookingDetails.guest.lastName  = $rootScope.userDetails.user.last_name

      # We only save the country code inside user object, so we need to reformat it to match the correct format
      if AppSettings.defaultCountryInCheckout.hasDefaultCountry
        country = CountryService.getDefaultCountry()
        if country
          $scope.bookingDetails.guest.country = { id: country.code, code: country.code, text: country.name }
    else
      populateMembershipDetails([])

    $scope.switchAddressPopulate = (el) ->
      $scope.autoPopulateAddress = !$scope.autoPopulateAddress
      if $scope.autoPopulateAddress
        populateAddressFields()
      else
        clearAddressFields()

    booking = new BookingsService($rootScope.bookingKey)

    $scope.isRestrictiveBooking = false
    $scope.loadingCancellationPolicy = true
    bookingPolicyTask = new BookingPolicyRunner($rootScope.hotelId, $rootScope.checkInDate)
    fetchCancellationPolicy = () ->
      bookingPolicyTask.fetch($rootScope.bookingKey).then (resolveResult) ->
        # Add explicit warning if restrictive words occur in the cancellation policy
        $scope.cancellation_policy = resolveResult
        $scope.isRestrictiveBooking = resolveResult.restrictive
        $scope.loadingCancellationPolicy = false
      , (rejectResult) ->
        $scope.cancellation_policy = rejectResult
        $rootScope.bookingKeysBlackList.push $rootScope.bookingKey
        openHotelRoomUnavailableModal()
    fetchCancellationPolicy()

    $scope.hotelPriceIsReady = false

    roomPriceCall = null
    # Call server to return a single hotel room price, specified by bookingKey in url params
    getSingleRoomPrice = (couponCode = "") ->
      roomPriceCall = HotelUtilService.getSingleRoomPrice(
        $rootScope.hotelId,
        $rootScope.bookingKey,
        couponCode,
      )
      roomPriceCall.then (hotelPrices) ->
        $scope.hotelPrices = hotelPrices
        $scope.roomBookingVoucherType = ""
        $timeout( ->
          # Add delay to prevent auto focus triggered on select2 countries formatSelection
          $scope.hotelPriceIsReady = true
        , 100)
        if hotelPrices?
          priceFound = false
          angular.forEach hotelPrices.rooms, (room_package) ->
            if room_package.key == $rootScope.bookingKey
              priceFound = true
              $scope.hotelPrice = HotelPricePopulateService.populatePrice(room_package, $scope)

              initializeExtraHotelInfo($scope.hotelPrice)

              if room_package.voucher_type_id
                $scope.roomBookingVoucherType = $rootScope.landingPage.voucherTypes[room_package.voucher_type_id]
                $scope.hotelPrices.voucher_type_id = room_package.voucher_type_id

              if $rootScope.landingPage.hasProductType("redeem")
                $scope.hotelPrice = PayWithPointsCashService.calculatePointsCashToPay($scope.hotelPrice)

              if $rootScope.landingPage.hasProductType("complimentary-nights")
                $scope.hotelPrice = PayWithPointsCashCompNights.calculatePointsCashToPay($scope.hotelPrice)

              TrackerService.hotelCheckoutInitiation(
                {
                  pricePerRoomPerNight: $scope.hotelPrice.averageRoomPrice(),
                  description: $scope.hotelPrice.roomNormalizedDescription
                },
                $scope.hotelDetail,
                {
                  guests: $rootScope.guests
                  currencyCode: $rootScope.routeParams.currency
                  checkInDate: $rootScope.checkInDate
                  checkOutDate: $rootScope.checkOutDate
                }
              )

              initPartnerPoint()
              PointsAdjustmentService.showInsufficientPointBalance(
                $scope.hotelPrice.points_payment
                $scope.hotelPrice.minPoint
              )

          if !priceFound
            openHotelRoomUnavailableModal()

        else
          openHotelRoomUnavailableModal()
        return

    $scope.couponService = CouponService
    CouponService.initializeCouponDetails()

    getSingleRoomPrice()

    initPartnerPoint = ->
      return if !$scope.hotelPrice
      $scope.partnerScore.base = $scope.hotelPrice.points if $scope.hotelPrice.points?
      $scope.partnerScore.bonus = $scope.hotelPrice.bonuses if $scope.hotelPrice.bonuses?

    $rootScope.redirectToHotelDetailsPage = ->
      $location.url(SearchUrlService.createBackToHotelDetailsUrl())

    $scope.$on "$locationChangeStart", ->
      bookingPolicyTask.cancel()
      $timeout.cancel(roomPriceCall) if roomPriceCall
      return

    $scope.select2Options =
      minimumResultsForSearch: -1

    focusOnNextField = (code) ->
      if CountryService.isStateRequired(code)
        $timeout( () ->
          $("#s2id_guest-state").select2("open")
        , 10)
      else
        focusOnTermsCheckbox()

    focusOnTermsCheckbox = ->
      $timeout( () ->
        $('#uTermsCheckbox').focus()
      , 10)

    select2CountriesPristine = true

    if $rootScope.userDetails.user
      CountryService.getState($rootScope.userDetails.user.country,
                              $rootScope.userDetails.user.state)
        .then (state) ->
          $scope.bookingDetails.guest.state = state

    $scope.selectFamilyMiles = (selected) ->
      $scope.familyMilesSelected = selected

    $scope.showSavedCreditCards = ->
      PaymentMethodService.allowSaveCreditCard() &&
      $scope.storedCreditCards &&
      $scope.storedCreditCards.length > 0

    $scope.addNewCreditCard = () ->
      (
        !$scope.checkoutState.selectedCreditCard ||
        !$scope.showSavedCreditCards() ||
        AppSettings.hasMultiplePaymentMethods
      )

    $scope.ccValidation = (ccNumberInput, element) ->
      CCValidationService.validate
        scope: $scope,
        ccNumberInput: ccNumberInput,
        formInput: $scope.checkOutForm.uCreditCardNumber,
        params:
          roomPrice: $scope.leftToPay(),
          basePoints: $scope.partnerScore.base,
        displayChangePartnerCallback: (selection) ->
          if selection == "yes"
            initPartnerPoint()
            getSingleRoomPrice()
            $scope.ccValidation()


    $scope.sumOfVouchers = ->
      $scope.vouchers.reduce (sum, voucher) ->
        sum + voucher.value_in_usd
      , 0

    $scope.leftToPay = ->
      if $scope.hotelPrice && !$scope.landingPage.hasProductType("voucher")
        $scope.hotelPrice.totalPrice - $scope.sumOfVouchers()
      else
        0

    $scope.cashToPay = ->
      return 0 if !$scope.hotelPrice
      return $scope.hotelPrice.cash_payment if ($scope.landingPage.complimentaryOrRedeem() && $scope.hotelPrice.cash_payment > 0)
      return CurrenciesService.convertFromUsd($scope.leftToPay())

    formatCurrencyValue = (value) ->
      formatValue(CurrenciesService.convertFromUsd(value))

    adjustCurrencyValue = (value) ->
      converted = CurrenciesService.convertFromUsd(value)
      formatValue(CurrenciesService.adjustDecimals(converted, 2))

    formatValue = (value) ->
      if $rootScope.selectedCurrency.decimalPlace == 0
        $filter("numberFmt")(Math.ceil(value), $rootScope.selectedLocale, 0)
      else
        $filter("numberFmt")(value, $rootScope.selectedLocale, 2)

    initializeExtraHotelInfo = (hotelPrice) ->
      displayFields = hotelPrice.roomAdditionalInfo.displayFields
      state = $scope.checkoutState
      state.checkinInstructions = displayFields.check_in_instructions
      state.specialCheckinInstructions = displayFields.special_check_in_instructions
      state.knowBeforeYouGo = displayFields.know_before_you_go
      state.feesOptional = displayFields.fees_optional
      state.feesMandatory = displayFields.fees_mandatory

    validMembershipField = () ->
      form = $scope.checkOutForm
      return (
        !form.uMemberNum || !form.uMemberNum.$viewValue ||
        (form.uMemberNum && form.uMemberNum.$viewValue && form.uMemberNum.$valid)
      )

    validateBooking = () ->
      form = $scope.checkOutForm

      if PointsAdjustmentService.showInsufficientPointBalance(
        $scope.hotelPrice.points_payment
        $scope.hotelPrice.minPoint
      )
        form.submitted = true
        return

      if CheckoutValidationService.isUserIneligible()
        CheckoutValidationService.openUserIneligibilityCheckModal()
        form.submitted = true
        return

      stripeIntentFormValid = true
      if(
        AppSettings.stripePaymentIntentsEnabled && $scope.checkoutState.showPaymentForm() &&
        ($scope.bookingDetails.paymentChannel == "stripe_payment_intents" ||
         $scope.bookingDetails.paymentChannel == "credit_card")
      )
        stripeIntentFormValid = StripePaymentIntentService.validateStripeIntentForm()

      if (
        !$scope.checkoutState.showPaymentForm() ||
        AdyenFormService.validAdyenForm($scope.bookingDetails.paymentChannel, $scope.bookingDetails.payment.card)
      ) && stripeIntentFormValid && form.$valid && validMembershipField()
        purchaseHotelBooking()
      else
        CheckoutValidationService.scrollToInvalidField()
        form.submitted = true
      return

    $scope.purchaseHotelBookingValidation = ->
      if AppSettings.checkUserStillLoggedin
        ApiDataService.get('user')
        .then (user) ->
          validateBooking() if user
          $scope.globalState.openLogin() if !user
        , (errorResponse) ->
          $scope.globalState.openLogin()
      else
        validateBooking()

    usePaymentChannel = ->
      return (
        !$scope.isExternalPaymentProvider() &&
        !$rootScope.landingPage.hasProductType("voucher")
      )

    updateVoucherSubHeader = ->
      $scope.voucherSubHeaderTxt = $filter('translate')('wl.total_nights_vouchers',
        { totalNights: $scope.totalNights })
      return

    # This covers all cases
    # OTA -> always tokenize
    # EARN -> always tokenize
    # CashVoucherEarn -> always tokenize
    # free_night -> never tokenize
    # pwp -> tokenize when cash > 0
    requiresTokenization = ->
      !$scope.landingPage.complimentaryOrRedeem("redeem") ||
      ($scope.landingPage.complimentaryOrRedeem("redeem") && $scope.hotelPrice.cash_payment > 0)

    $scope.$watch "selectedLocale", (newValue, oldValue) ->
      displayHotelDatesFormat()
      CheckoutFormService.updateCheckoutError($scope)
      updateVoucherSubHeader()
      $rootScope.$broadcast('rzSliderForceRender')
      # JY: Temporarily disabling this as its causing RXML issues
      # fetchCancellationPolicy()

    $scope.$watch "checkoutState.selectedCreditCard", (newValue, oldValue) ->
      return if !newValue || (oldValue && newValue.token == oldValue.token)
      PaymentMethodService.selectedCreditCard = newValue

    isNotAdyenChannel = ->
      return !$scope.bookingDetails.paymentChannel.includes('adyen')

    openImpersonatedModal = ->
      if CheckoutValidationService.impersonatedUserIsReadOnly()
        SimpleModalService.open("impersonated-read-only-modal")
      else
        SimpleModalService.open("impersonated-confirmation-modal")
        .then(() ->
          processPurchaseHotelBooking()
        )

    $rootScope.$watch "selectedCurrency", (newValue, oldValue) ->
      HotelPricePopulateService.updateFeeBreakdown($scope.hotelPrice)

    purchaseHotelBooking =->
      if CheckoutValidationService.impersonatedModalCheck()
        openImpersonatedModal()
      else
        processPurchaseHotelBooking()

    processPurchaseHotelBooking = ->
      PaymentTimerService.paymentInProgressTimer()
      $scope.paymentIsLoading = true
      CheckoutStatusService.resetState()
      stateValue = if !isUndefinedOrNull($scope.bookingDetails.guest.state) && !isUndefinedOrNull($scope.bookingDetails.guest.state.code) then $scope.bookingDetails.guest.state.code else ''
      $scope.bookingDetails.guest.specialRequests = if isUndefinedOrNull($scope.bookingDetails.guest.specialRequests) then '' else $scope.bookingDetails.guest.specialRequests
      if $scope.paymentMethod.activePaymentTab == "pay-anyone"
        $scope.bookingDetails.paymentChannel = "pay_anyone"
      if usePaymentChannel() && isNotAdyenChannel() && !PaymentMethodService.isUsingSavedAdyenCard()
        cardType = Stripe.card.cardType($scope.bookingDetails.payment.card.number)

        switch $rootScope.selectedCurrency.preferredGateway
          when "checkout_com"
            CheckoutComKitService.cardToken($scope.bookingDetails.payment.card).then((token) ->
              createBooking(stateValue, "checkout_com", token)
            , (errors) ->
              $scope.paymentIsLoading = false
              $scope.checkoutState.errorKey = "Payment Error"
              Rollbar.info("checkout_com: Unable to create payment source. Error: " + errors[0])
            )
          else
            # populate credit card from payment method service if there are multiple payment methods
            # will need to change this if there are other default payment methods other than a credit card
            if AppSettings.hasMultiplePaymentMethods
              $scope.checkoutState.selectedCreditCard = $scope.paymentMethod.selectedCreditCard

            if requiresTokenization()
              startStripeBooking(stateValue)
            else
              createBooking(stateValue, "stripe")
      else
        # alipay and free night just go and create booking right away
        createBooking(stateValue, $scope.bookingDetails.paymentChannel)
      return

    startStripeBooking = (stateValue) ->
      # for Bounty that not require user country code + not use appSettings.defaultCountryInCheckout
      countryCode = if $scope.bookingDetails.guest.country then $scope.bookingDetails.guest.country.code else ''

      if AppSettings.stripePaymentIntentsEnabled
        # NEW STRIPE PAYMENT INTENT FLOW
        $scope.bookingDetails.paymentChannel = "stripe_payment_intents"
        if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
          $scope.bookingDetails.newCardSave = true
        if StripePaymentIntentService.isUsingSavedCard()
          $scope.bookingDetails.payment.card.paymentMethod = PaymentMethodService.selectedCreditCard.token
          $scope.bookingDetails.payment.card.customer = PaymentMethodService.selectedCreditCard.customer_id
        createBooking(stateValue, "stripe_payment_intents")
      else if AppSettings.storeCreditCard && $scope.checkoutState.selectedCreditCard
        createBooking(stateValue, "stripe", $scope.checkoutState.selectedCreditCard.token)
      else
        # OLD STRIPE PAYMENT FLOW
        StripePaymentService.getToken(
          $scope.bookingDetails.payment.card.number,
          $scope.bookingDetails.payment.card.cvv,
          $scope.bookingDetails.payment.card.expirationMonth,
          $scope.bookingDetails.payment.card.expirationYear,
          $scope.bookingDetails.payment.card.firstName,
          $scope.bookingDetails.guest.street,
          $scope.bookingDetails.guest.city,
          stateValue,
          $scope.bookingDetails.guest.postalCode,
          countryCode
        ).then(((token) ->
          if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
            usage = 'reusable'
            $scope.bookingDetails.newCardSave = true
          else
            usage = 'single_use'
          StripePaymentService.createSource(token, usage).then(((source) ->
            createBooking(stateValue, "stripe", source)
          ), (error) ->
            handleStripeError("Stripe: Unable to create payment source. Error: ", error)
          )
        ), (error) ->
          handleStripeError("Stripe: Unable to tokenize payment details. Error: ", error)
        )

    $scope.removeCreditCard = (nonce, payment_channel) ->
      PaymentService.removeStoredPayment(nonce, payment_channel).then (res) ->
        PaymentService.getStoredPayments().then (res) ->
          updateStoredPayments(res)

    handleStripeError = (message, error) ->
      $scope.paymentIsLoading = false
      $scope.checkoutState.errorKey = "Payment Error"
      Rollbar.info(message + error)

    debugInfo = (hotel) ->
      {
        points_payment: if hotel.points_payment? then Math.max(hotel.points_payment, 0) else 0,
        cash_payment: formatValue($scope.cashToPay()),
        points_earned: if hotel.points? then hotel.points else 0,
        bonus_programs: hotel.bonus_programs,
        bonus_tiers: hotel.bonus_tiers,
        exchange_rate: $rootScope.convert_rate,
      }

    paddedDateValue = (value) ->
      return if value < 10  then "0" + value else value

    createBooking = (stateValue, paymentChannel, paymentNonce, paymentNonceType = 'card_token') ->
      AppState.paymentChannel = paymentChannel

      adyenPaymentType = "default"
      adyenToken = ""
      if PaymentMethodService.isUsingSavedAdyenCard()
        adyenPaymentType = "adyen-recurring"
        adyenToken = PaymentMethodService.selectedCreditCard.token
        paymentChannel = PaymentMethodService.getPaymentChannelFromAdyenSavedCard()

      if PaymentMethodService.allowSaveCreditCard() && $scope.checkoutState.saveCreditCard
        $scope.bookingDetails.newCardSave = true

      # IF user detail name is hidden, use the guest firstName
      if $rootScope.globalState.checkoutHideUserDetailName && $rootScope.userDetails && $rootScope.userDetails.user
        $scope.bookingDetails.user.firstName = $rootScope.userDetails.user.first_name
      bookingCall=
        booking.create_booking(
          $scope.bookingDetails.guest.title,
          $scope.bookingDetails.guest.firstName,
          $scope.bookingDetails.guest.lastName,
          $scope.bookingDetails.guest.email,
          $scope.bookingDetails.guest.city,
          $scope.bookingDetails.user.rememberMe,
          stateValue,
          $scope.bookingDetails.guest.houseNumber,
          $scope.bookingDetails.guest.street,
          $scope.bookingDetails.guest.postalCode,
          if $scope.bookingDetails.guest.country then $scope.bookingDetails.guest.country.code else 'XX',
          $scope.bookingDetails.payment.card.firstName,
          $scope.bookingDetails.payment.card.lastName,
          $scope.bookingDetails.payment.card.number,
          $scope.bookingDetails.payment.card.cvv,
          $scope.bookingDetails.payment.card.expirationMonth,
          $scope.bookingDetails.payment.card.expirationYear,
          $rootScope.bookingKey,
          $scope.hotelPrice.roomNormalizedDescription,
          $scope.bookingDetails.selectedMembership.member_no,
          $scope.bookingDetails.selectedMembership.member_first_name,
          $scope.bookingDetails.selectedMembership.member_last_name,
          $rootScope.pointsPartner.id,
          $rootScope.selectedCurrency.code,
          $scope.bookingDetails.user.email,
          $scope.bookingDetails.user.firstName,
          $scope.bookingDetails.user.send_marketing,
          $scope.bookingDetails.guest.specialRequests,
          $scope.vouchers,
          formatPhoneNumber(),
          paymentChannel,
          $scope.hotelDetail.name,
          paymentNonce,
          paymentNonceType,
          $scope.bookingDetails.guest.streetNumber,
          $scope.bookingDetails.payment.card.type,
          $scope.bookingDetails.user.redemption_password,
          if $rootScope.landingPage.complimentaryOrRedeem() then PointsCashShareService.pointsCashShare.value else 0,
          $rootScope.globalState.referrer,
          debugInfo($scope.hotelPrice),
          $scope.bookingDetails.otp,
          if $scope.checkoutState.displayFamilyMiles() then $scope.familyMilesSelected else false,
          if CouponService.isValid() then CouponService.couponCodeDetails.code else "",
          if $rootScope.useProductType() then $rootScope.productTypeAdapter($rootScope.globalState.productType),
          $scope.bookingDetails.newCardSave,
          AdyenFormService.adyenEncryptedData($scope.bookingDetails.payment.card),
          $scope.bookingDetails.browserInfo,
          $scope.bookingDetails.payment.card.paymentMethod,
          $scope.bookingDetails.payment.card.customer,
          adyenPaymentType,
          adyenToken
        )

      bookingCall.then (resolveResult) ->
        $rootScope.bookingTransactionId = resolveResult.transaction_id
        fetchBookingStatus()
        $scope.paymentIsLoading = false
      , (rejectResult) ->
        $scope.paymentIsLoading = false
        if rejectResult.status && rejectResult.status.length != 0
          error = rejectResult.status[0]["error"]
        else if rejectResult.errors && rejectResult.errors.length != 0
          error = rejectResult.errors[0]
        else
          error = ""
        $scope.checkoutState.errorKey = error

    isUndefinedOrNull = (value) ->
      (typeof value == 'undefined' || value == null)

    hideQueryStringForUndefinedOrNull = (key, value) ->
      if isUndefinedOrNull(value)
        return ''
      else
        qstr = '&' + key + '=' + value
        return qstr

    $scope.applyCouponCode = (code) ->
      CouponService.validateCouponCode(code).then((res) ->
        $scope.hotelPriceIsReady = false
        CouponService.validatingPriceChanged = true
        getSingleRoomPrice(code)
      ).finally(() ->
        if !$scope.hotelPrice.has_discount_for_coupon_code && !CouponService.hasErrorMessage()
          CouponService.couponCodeDetails.response = {
            valid: false,
            errorMsg: "Conditions not met",
          }
        CouponService.validatingPriceChanged = false
      )

    $scope.resetCouponCode = ->
      CouponService.resetCouponCode()
      getSingleRoomPrice()

    generateVoucherQueryString = ->
      vouchers = $scope.vouchers || []
      return (voucher.uid for voucher in $scope.vouchers).reduce ((x,y) -> x + "&voucher_ids" + "[]=" + y), ""

    trackBooking = (bookingId) ->
      # Single room hotel price might not have been fetched yet, so we should retry the tracking until it finishes
      if isUndefinedOrNull($scope.hotelPrice)
        $timeout( ->
          trackBooking(bookingId)
        , 200)
        return

      TrackerService.hotelBookingConfirmation(
        {
          bookingId: bookingId,
          pricePerRoomPerNight: $scope.hotelPrice.averageRoomPrice(),
          description: $scope.hotelPrice.roomNormalizedDescription,
          taxValue: $scope.hotelPrice.taxAndRecoveryCharges(),
          bookingCost: $scope.hotelPrice.unformattedGrandTotal(),
        },
        $scope.hotelDetail,
        {
          guests: $rootScope.guests
          currencyCode: $rootScope.selectedCurrency.code
          checkInDate: $rootScope.checkInDate
          checkOutDate: $rootScope.checkOutDate
        }
      )

    $scope.displayMembershipForm = ->
      (
        $rootScope.pointsPartner && $rootScope.pointsPartner.category == 'airline' &&
        $rootScope.landingPage && $rootScope.landingPage.earnMiles() &&
        $rootScope.globalState.displayMembershipAtCheckout
      )

    $scope.validateMembership = (pointsPartner, key) ->
      form = $scope.checkOutForm

      clearFieldWithError = ->
        form.uMemberNum.$setValidity("pattern", true)
        form.$setValidity("membershipNum", true)
        form.uMemberNum.$validate()

      markFieldWithError = (errors) ->
        if errors[key]
          form.uMemberNum.$setValidity("pattern", false)
          form.$setValidity("membershipNum", false)
          form.uMemberNum.$validate()
        else
          clearFieldWithError()

      # if no membership number is entered, just take it as a valid action
      if !form.uMemberNum.$viewValue || !$rootScope.isUserLoggedIn()
        clearFieldWithError()
        form.uMemberNum.$setPristine()
        return

      data = {
        points_partner_id: pointsPartner.id,
        member_no: form.uMemberNum.$viewValue,
      }
      # only enter membership first and last name when they are required ( rendered on form )
      data.member_first_name = form.uMemberFirstName.$viewValue if form.uMemberFirstName
      data.member_last_name = form.uMemberLastName.$viewValue if form.uMemberLastName

      # prevent form being submitted when we still waiting for validation frm server
      form.$setValidity("membershipNum", false)

      ApiDataService.post('memberships/validate', data).then(() ->
        clearFieldWithError()
      , (data) ->
        markFieldWithError(data['errors'])
      )
      return

    formatPhoneNumber = () ->
      phoneNumber = $scope.bookingDetails.user.phoneNumber
      if phoneNumber
        phoneCountryCode = $scope.bookingDetails.user.phoneCode.id
        trimmedPhoneNumber = phoneNumber.toString().replace(/[^\d]/g,'')
        return "#{phoneCountryCode} #{trimmedPhoneNumber}"
      else
        return ""

    $scope.policyShowCash = () ->
      return $rootScope.landingPage.earnMiles()

    $scope.policyShowPercentage = () ->
      return $rootScope.landingPage.hasProductType("redeem")

    $scope.$on 'pointsCashSliderUpdate', ((event, data) ->
      if $rootScope.landingPage.hasProductType("redeem")
        $scope.hotelPrice = PayWithPointsCashService.calculatePointsCashToPay($scope.hotelPrice)
      if $rootScope.landingPage.hasProductType("complimentary-nights")
        $scope.hotelPrice = PayWithPointsCashCompNights.calculatePointsCashToPay($scope.hotelPrice)
      if !select2CountriesPristine || !select2StatesPristine
        select2CountriesPristine = select2StatesPristine = !($scope.hotelPrice && $scope.hotelPrice.cash_payment > 0)
      #Set payment channel to default "credit_card" if fully pay by points to get rid of backend payment creation errors
      if $scope.hotelPrice.cash_payment == 0
        $scope.bookingDetails.paymentChannel = "credit_card"
      PointsAdjustmentService.pointsNeeded = $scope.hotelPrice.points_payment
    ), true

    $scope.checkoutSettings = AppSettings

    updateVoucherSubHeader()

    $scope.updateSelectedCreditCard = (cards) ->
      $scope.checkoutState.selectedCreditCard = $scope.getSelectedCreditCard(cards)

    updateStripeCardPaymentChannel = (stripeArray) ->
      stripeArray.forEach (card) ->
        card["payment_channel"] = "stripe"

    updateStoredPayments = (res) ->
      updateStripeCardPaymentChannel(res["stripe"])
      PaymentMethodService.adyenSavedCards = res["adyen"]
      PaymentMethodService.savedCards = res["stripe"]
      $scope.filterSavedCards()

    if AppSettings.storeCreditCard && $rootScope.userDetails.loggedIn
      $scope.checkoutState.userPaymentMethodIsFetching = true
      PaymentService.getStoredPayments().then (res) ->
        $scope.checkoutState.userPaymentMethodIsFetching = false
        updateStoredPayments(res)
      , () ->
        $scope.checkoutState.userPaymentMethodIsFetching = false

    $scope.hasDiscountedPrice = () ->
      PayWithPointsCashService.hasDiscountedPrice($scope.hotelPrice)

    $scope.getPriceBeforeDiscount = () ->
      if $rootScope.landingPage.hasProductType("redeem")
        PayWithPointsCashService.getPriceBeforeDiscount($scope.hotelPrice)
      else if $rootScope.landingPage.hasProductType("complimentary-nights")
        PayWithPointsCashCompNights.getPriceBeforeDiscount($scope.hotelPrice)

    # condition for showing `remember-me` tooltip at checkout page
    isShowingTooltip = false
    $scope.showRememberMePopUp = () ->
      return isShowingTooltip

    $scope.toggleRememberMePopUp = (state) ->
      isShowingTooltip = state

    $scope.$on '$destroy', ->
      CheckoutStatusService.resetState()

    $scope.$on 'adyen-form-update', (_event, stateData, name) ->
      $scope.bookingDetails.payment.card.firstName = name if name
      return unless stateData
      $scope.bookingDetails.browserInfo = stateData.browserInfo
      $scope.bookingDetails.payment.card.encryptedCardNumber =
        stateData.paymentMethod.encryptedCardNumber
      $scope.bookingDetails.payment.card.encryptedExpiryMonth =
        stateData.paymentMethod.encryptedExpiryMonth
      $scope.bookingDetails.payment.card.encryptedExpiryYear =
        stateData.paymentMethod.encryptedExpiryYear
      $scope.bookingDetails.payment.card.encryptedSecurityCode =
        stateData.paymentMethod.encryptedSecurityCode

    $scope.isInternetExplorer = () ->
      UserAgentChecker.isIE()

    $scope.operatingSystemClass = () ->
      UserAgentChecker.getOS()

    $scope.cancellationFee = () ->
      AppConfig.cancellation_fee_percentage

    $scope.cancellationFeeText = AppSettings.cancellationFeeText.checkout

    $scope.alwaysShowCancellationFee = $scope.cancellationFee() > 0 && AppSettings.alwaysShowCancellationFee

    $scope.checkEventCode = (event) ->
      return (event.code == "Enter" || event.keyCode == 13) ||
        (event.code == "Space" || event.keyCode == 32)

    $scope.zeroFullCashPayment = () ->
      return PointsCashPaymentHandlingService.zeroFullCashPayment(
        $scope.hotelPrice?.cash_payment,
        $scope.hotelPrice?.points_payment
      )

    $scope.zeroFullCashPaymentFirstRoom = () ->
      return PointsCashPaymentHandlingService.zeroFullCashPayment(
        $scope.hotelPrice?.rooms[0].cash_payment,
        $scope.hotelPrice?.rooms[0].points_payment
      )

    $scope.stripePaymentIntentsEnabled = AppSettings.stripePaymentIntentsEnabled

    $scope.setPaymentActiveTab = (paymentMethod) ->
      PaymentMethodService.setActiveTab(paymentMethod)

]
.controller 'kaligoCheckoutCtrl', [
  '$scope', '$rootScope', 'CookieService'
  ($scope, $rootScope, CookieService) ->
    $scope.$watch 'hotelPrice', ((newValue) ->
      return if $scope.hotelPrice == undefined
      points = newValue.points
      bonuses = newValue.bonuses
      $rootScope.pointsPartnersScore = points
      $rootScope.pointsPartnersBonus = bonuses
      # TODO hack need to remove
      CookieService.setCookie('partner_points', $scope.pointsPartnersScore)
    ), true
]
.controller 'kaligoSuccessCtrl', [
  '$scope', 'CookieService'
  ($scope, CookieService) ->
    # TODO hack need to remove
    $scope.partner_points = CookieService.fetchCookie('partner_points')
]
